<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item v-if="!$store.state.user.site_id">
                    <tb-select ref="site" :options="siteOptions"
                               @change="val => this.options.params.site_id = val"/>
                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="options.params.status" placeholder="提现状态筛选">
                        <el-option :key="1" :value="1" label="审核中"></el-option>
                        <el-option :key="2" :value="2" label="已完成"></el-option>
                        <el-option :key="3" :value="3" label="已驳回"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="options.params.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
                <el-form-item v-if="$store.state.user.site_id && $perm('league_SiteWithdrawal_apply')">
                    <el-button type="primary" @click="$refs.withdrawal.show()" icon="el-icon-finished">申请提现</el-button>
                </el-form-item>
            </template>
            <template slot="actions" slot-scope="scope">
                <template v-if="!$store.state.user.site_id && scope.row.status === 0 && $perm('league_SiteWithdrawal_calc')">
                    <el-button @click="doCalc(scope.row.id, 1, '提现完成')" type="text" icon="el-icon-check">完成</el-button>
                    <el-button @click="doReject(scope.row.id)" type="text" icon="el-icon-close" class="color-warning">
                        驳回
                    </el-button>
                </template>
            </template>
        </crud>
        <!-- 提现表单 -->
        <tb-dialog ref="withdrawal" title="提现申请" @onOpen="onWithdrawalOpen" @onClose="onWithdrawalClose"
                   @onConfirm="onWithdrawalConfirm">
            <el-form ref="withdrawalForm" :model="withdrawalForm" :rules="withdrawalFormRules" label-width="80px">
                <el-form-item label="账户余额">
                    <el-input v-model="withdrawalForm.balance" readonly>
                        <el-button slot="append">元</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="最少提现">
                    <el-input v-model="withdrawalForm.site_limit_withdrawal" readonly>
                        <el-button slot="append">元</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="手续费率">
                    <el-input v-model="withdrawalForm.service_rate" readonly>
                        <el-button slot="append">%</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="提现金额" prop="amount">
                    <el-input v-model="withdrawalForm.amount" type="number" clearable @input="onAmountChange"
                              placeholder="请输入提现金额">
                        <el-button slot="append" @click="doAll">全部</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="手续费额">
                    <el-input v-model="withdrawalForm.service_amount" readonly>
                        <el-button slot="append">元</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="实际到账">
                    <el-input v-model="withdrawalForm.real_amount" readonly>
                        <el-button slot="append">元</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="真实名称" prop="real_name">
                    <el-input v-model="withdrawalForm.real_name" clearable placeholder="请输入收款人姓名或对公名字"></el-input>
                </el-form-item>
                <el-form-item label="收款账户" prop="account">
                    <el-input v-model="withdrawalForm.account" clearable placeholder="请输入银行卡号"></el-input>
                </el-form-item>
                <el-form-item label="开户银行" prop="bank">
                    <el-input v-model="withdrawalForm.bank" clearable placeholder="请输入开户行名称"></el-input>
                </el-form-item>
            </el-form>
        </tb-dialog>
    </div>
</template>

<script>
export default {
    name: "Site",
    data: function () {
        return {
            status: ['审核中', '已完成', '已驳回'],
            options: {
                addBtn: false,
                editBtn: false,
                delBtn: true,
                pager: true,
                actionWidth: 180,
                addUrl: 'league.SiteWithdrawal.add',
                addPerm: 'league_SiteWithdrawal_add',
                editUrl: 'league.SiteWithdrawal.edit',
                editPerm: 'league_SiteWithdrawal_edit',
                delUrl: 'league.SiteWithdrawal.delete',
                delPerm: 'league_SiteWithdrawal_delete',
                listUrl: 'league.SiteWithdrawal.page',
                formLabelWidth: '100px',
                params: {
                    status: null,
                    date: null
                },
                columns: [
                    {
                        prop: "site_id", label: "提现站点", formatter: row => {
                            return row.site ? row.site.name : '-'
                        }
                    },
                    {prop: "amount", label: "提现金额(元)",},
                    {prop: "service_rate", label: "平台率(%)",},
                    {prop: "service_amount", label: "手续费(元)",},
                    {prop: "real_amount", label: "实际到账(元)",},
                    {prop: "real_name", label: "收款账户名字", required: true},
                    {prop: "account", label: "收款账户", required: true},
                    {prop: "bank", label: "开户行名称", required: true},
                    {prop: "calc_time", label: "处理时间",},
                    {
                        prop: "admin_id", label: "处理人", formatter: row => {
                            return row.admin ? row.admin.nickname : ''
                        }
                    },
                    {prop: "result", label: "处理结果",},
                    {
                        prop: "status", label: "提现状态", formatter: row => {
                            return this.status[row.status]
                        }
                    },
                    {prop: "create_time", label: "提交时间",}
                ],
            },
            siteOptions: {
                listUrl: 'league.Site.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '站点筛选'
            },
            withdrawalForm: {
                amount: null,
                service_rate: null,
                service_amount: null,
                real_amount: null,
                real_name: null,
                account: null,
                bank: null,
                site_limit_withdrawal: null,
                balance: null,
            },
            withdrawalFormRules: {
                amount: [
                    {required: true, message: '请输入提现金额~', trigger: 'blur'}
                ],
                real_name: [
                    {required: true, message: '请输入真实名字~', trigger: 'blur'}
                ],
                account: [
                    {required: true, message: '请输入提现账号~', trigger: 'blur'}
                ],
                bank: [
                    {required: true, message: '请输入开户行名称~', trigger: 'blur'}
                ]
            }
        }
    },
    activated: function () {
        if (!this.$store.state.user.site_id) {
            this.$refs.site.getLists()
        }
    },
    methods: {
        onWithdrawalOpen: function () {
            this.$helper._get(this, 'league.SiteWithdrawal.info', {}, resp => {
                this.withdrawalForm = resp.data
            })
        },
        onWithdrawalClose: function () {
            this.$refs.withdrawalForm.clearValidate()
            this.withdrawalForm = {
                amount: null,
                service_rate: null,
                service_amount: null,
                real_amount: null,
                real_name: null,
                account: null,
                bank: null,
                site_limit_withdrawal: null,
                balance: null,
            }
        },
        doAll: function () {
            this.withdrawalForm.amount = this.withdrawalForm.balance
            this.withdrawalForm.service_amount = (Number(this.withdrawalForm.amount) * Number(this.withdrawalForm.service_rate) / 100).toFixed(2)
            this.withdrawalForm.real_amount = (this.withdrawalForm.amount - this.withdrawalForm.service_amount).toFixed(2)
        },
        onAmountChange: function (val) {
            if (!val) {
                this.withdrawalForm.service_amount = 0
                this.withdrawalForm.real_amount = 0
                return
            }
            if (Number(val) < 0) {
                this.withdrawalForm.amount = 0
                this.withdrawalForm.service_amount = 0
                this.withdrawalForm.real_amount = 0
                return;
            }
            if (Number(val) > Number(this.withdrawalForm.balance)) {
                this.withdrawalForm.amount = this.withdrawalForm.balance
            }
            this.withdrawalForm.service_amount = (Number(this.withdrawalForm.amount) * Number(this.withdrawalForm.service_rate) / 100).toFixed(2)
            this.withdrawalForm.real_amount = (this.withdrawalForm.amount - this.withdrawalForm.service_amount).toFixed(2)
        },
        onWithdrawalConfirm: function () {
            this.$refs.withdrawalForm.validate(valid => {
                if (!valid) return
                if (Number(this.withdrawalForm.amount) <= 0) return this.$message.warning("提现金额必须大于0~")
                if (Number(this.withdrawalForm.amount) < Number(this.withdrawalForm.site_limit_withdrawal)) return this.$message.warning("提现金额不能少于起提金额~")
                if (Number(this.withdrawalForm.amount) > Number(this.withdrawalForm.balance)) return this.$message.warning("提现金额不能大于账户余额~")
                this.$helper._post(this, 'league.SiteWithdrawal.apply', this.withdrawalForm, resp => {
                    this.$message.success(resp.message)
                    this.$refs.withdrawal.hide()
                    this.$refs.crud.getLists()
                })
            })
        },
        doCalc: function (id, status, result) {
            this.$helper._post(this, 'league.SiteWithdrawal.doCalc', {id: id, result: result, status: status}, resp => {
                this.$message.success(resp.message)
                this.$refs.crud.getLists()
            })
        },
        doReject: function (id) {
            this.$prompt('请输入驳回原因', '驳回提现').then(({value}) => {
                if (!value) {
                    return this.$message.warning("请输入驳回原因~")
                }
                this.doCalc(id, 2, value)
            })
        }
    }
}
</script>

<style scoped>

</style>
